<div class="login-container">
  <block-ui></block-ui>
    <div class="login-box">
      <h2>Iniciar Sesión</h2>

      <div *ngIf="errorMessage" class="error-message">
        <small>{{ errorMessage }}</small>
      </div>
      
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

        <div class="form-group">
          <label for="email">Correo Electrónico</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            [(ngModel)]="email" 
            #emailInput="ngModel" 
            required 
            email
            class="form-control" />
          <div *ngIf="emailInput.invalid && emailInput.touched" class="error-message">
            <small *ngIf="emailInput.errors?.required">El correo electrónico es obligatorio.</small>
            <small *ngIf="emailInput.errors?.email">El formato del correo no es válido.</small>
          </div>
        </div>
  
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input 
            type="password" 
            id="password" 
            name="password" 
            [(ngModel)]="password" 
            #passwordInput="ngModel" 
            required
            minlength="6"
            class="form-control" />
          <div *ngIf="passwordInput.invalid && passwordInput.touched" class="error-message">
            <small *ngIf="passwordInput.errors?.required">La contraseña es obligatoria.</small>
            <small *ngIf="passwordInput.errors?.minlength">La contraseña debe tener al menos 6 caracteres.</small>
          </div>
        </div>
        <button type="submit" [disabled]="loginForm.invalid" class="btn-login">Ingresar</button>
      </form>
    </div>
  </div>
  