<div class="page-container">
    <div class="header">
        <h2><mat-icon>person</mat-icon> Mi Perfil</h2>
    </div>

    <section class="section-container">
        <div class="section-left photo-container">
            <ng-container *ngIf="isBase64 && user.photo">
                <img *ngIf="user.photo" alt="Foto de perfil" class="profile-photo" [src]="user.photo">
            </ng-container>
            <ng-container *ngIf="!isBase64 && user.photo">
                <img *ngIf="user.photo" alt="Foto de perfil" class="profile-photo" [src]="user.photo">
            </ng-container>
            <div *ngIf="!user.photo" class="profile-photo">
                <span>{{ user?.name ? user.name[0] : '' }}</span>
            </div>
        </div>
        <div class="section-right">
            <p class="profile-name">{{user.name}}</p>
            <p class="profile-role">Administrador</p><a class="edit-profile-link" (click)="fileUpload.click()">Cambiar
                foto</a>

            <input style="display: none;" #fileUpload type="file" id="avatar" name="avatar"
                accept="image/png, image/jpeg" (change)="onFileSelected($event)" />
        </div>
    </section>

    <section class="section-container">
        <div class="section-left">
            <h2 class="section-title">Información personal</h2>
            <p class="section-description">.</p>
        </div>
        <div class="section-right">
            <div class="form-group">
                <label>Nombre y Apellido</label>
                <p class="field-value">{{ user.name }}</p>
            </div>
            <div class="form-group">
                <label>Celular</label>
                <p class="field-value">{{ user.phone }}</p>
            </div>
            <div class="form-group">
                <label>Correo electrónico</label>
                <p class="field-value">{{ user.email }}</p>
            </div>
        </div>
    </section>

    <a type="button" class="btn" [routerLink]="['/profile-edit']">
        Editar perfil
    </a>


</div>