import { Component, OnInit } from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UsersService } from 'src/app/shared/users.service';
import Swal from 'sweetalert2';

const convertBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  user: {
    id: string,
    preferences?: { purchaseType?: string },
    name?: string,
    lastname?: string,
    identificationNumber?: string,
    identificationType?: string,
    photo?: any,
    phone?: string,
    email?: string
  };
  isBase64 = false;
  avatar: any;

  constructor(private readonly usersService: UsersService) { }

  async ngOnInit(): Promise<void> {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodeToken = jwtDecode(accessToken);

      if (decodeToken) {
        this.user = { id: decodeToken.userId };

        await this.findById();
      }

    }

  }

  async findById() {

    this.blockUI.start('Cargando datos...');
    this.user = (await this.usersService.findById(this.user.id)).data;

    this.blockUI.stop();

  }

  async onFileSelected(event: any) {
    const file = event.target.files[0];
    this.user.photo = await this.processImage(await convertBase64(file));
    this.isBase64 = true;
    this.confirmPhoto();
  }

  processImage(base64) {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var width = img.width;
        var height = img.height;
        var resizedBase64 = null;
        while (resizedBase64 == null) {
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          if (canvas.toDataURL('image/png').length > 250 * 1000) {
            width = width * 0.9;
            height = height * 0.9;
          } else {
            resizedBase64 = canvas.toDataURL('image/png')
          }
        }
        resolve(resizedBase64);
      };
      img.src = base64;
    });
  }


  async confirmPhoto() {

    Swal.fire({
      title: "¿Confirma la actualización de la foto de perfil ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.blockUI.start('Actualizando datos...');
        const res = await this.usersService.updateProfile(this.user.id, this.user);
        this.blockUI.stop();
      } 
    });
  }

}
