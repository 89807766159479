import { Component, HostListener, OnInit } from '@angular/core';
import { CheckoutService } from 'src/app/shared/checkout.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.css']
})
export class ReserveComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  checkouts: any = [];
  showImageModal = false;
  showStatusModal = false;
  modalImageUrl = '';
  currentCheckout: any;
  actionType = '';
  isMobileView: boolean = false;

  constructor(private checkoutService: CheckoutService) { }

  ngOnInit(): void {
    this.loadCheckouts();
  }

  async loadCheckouts(): Promise<void> {
    this.blockUI.start('Cargando lista de reservas...');
    try {
      const response = await this.checkoutService.getCheckouts();
      if (response && Array.isArray(response.data)) {
        this.checkouts = response.data;
      } else {
        console.error('Datos recibidos no contienen un array en la propiedad "data":', response);
      }
    } catch (error) {
      console.error('Error al obtener los checkouts', error);
    }
    this.blockUI.stop();
  }

  openImageModal(imageUrl: string): void {
    // this.modalImageUrl = 'https://www.rnp.gob.pe/clave-bancos/img/voucher-bcp.png';
    this.modalImageUrl = imageUrl;
    this.showImageModal = true;
  }

  closeImageModal(): void {
    this.showImageModal = false;
  }

  handleAction(checkout: any, action: string): void {
    this.currentCheckout = checkout;
    this.actionType = action;
    this.showStatusModal = true;
  }

  approveCheckout(checkout: any): void {
    Swal.fire({
      title: '¿Estás seguro de aprobar este voucher?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, aprobar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.blockUI.start('Aprobando reserva...');
        this.checkoutService.updateStatus(checkout._id, 'APROBADO')
          .then(() => {
            this.loadCheckouts();
            Swal.fire('Aprobado', 'La reserva ha sido aprobada.', 'success');
          })
          .catch(error => {
            console.error('Error al aprobar la reserva:', error);
            Swal.fire('Error', 'No se pudo aprobar la reserva.', 'error');
          })
          .finally(() => {
            this.blockUI.stop();
          });
      }
    });
  }

  rejectCheckout(checkout: any): void {
    Swal.fire({
      title: '¿Estás seguro de rechazar esta reserva?',
      input: 'text',
      inputPlaceholder: 'Escribe el motivo del rechazo',
      inputValidator: (value) => {
        if (!value) {
          return 'Es necesario escribir un motivo para rechazar la reserva';
        }
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, rechazar'
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const motivoRechazo = result.value;
        this.blockUI.start('Rechazando reserva...');
        this.checkoutService.updateStatus(checkout._id, 'RECHAZADO', motivoRechazo)
          .then(() => {
            this.loadCheckouts();
            Swal.fire('Rechazado', 'La reserva ha sido rechazada.', 'success');
          })
          .catch(error => {
            console.error('Error al rechazar la reserva:', error);
            Swal.fire('Error', 'No se pudo rechazar la reserva.', 'error');
          })
          .finally(() => {
            this.blockUI.stop();
          });
      }
    });
  }

  cancelAction(): void {
    this.closeStatusModal();
  }

  closeStatusModal(): void {
    this.showStatusModal = false;
    this.currentCheckout = null;
  }

  updateReservations() {
    this.loadCheckouts();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobileView = window.innerWidth <= 600;
  }
}
