import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/shared/users.service';
import * as jwtDecode from 'jwt-decode';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  user: {
    id: string,
    preferences?: { purchaseType?: string },
    name?: string,
    lastname?: string,
    identificationNumber?: string,
    identificationType?: string,
    photo?: any,
    phone?: string,
    email?: string
  };

  purchaseTypes = ['INVERSION', 'VIVIENDA'];
  identificationTypes = ['DNI', 'PASAPORTE', 'CE'];
  constructor(private readonly usersService: UsersService, private router: Router) { }

  async ngOnInit(): Promise<void> {

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodeToken = jwtDecode(accessToken);
      this.user = { id: decodeToken.userId };

      await this.findById();
    }
  }

  async findById() {

    this.blockUI.start('Cargando datos...');
    this.user = (await this.usersService.findById(this.user.id)).data;
    this.user.preferences = this.user.preferences ?? { purchaseType: this.purchaseTypes[0] };

    this.blockUI.stop();

  }

  async save() {

    console.log('this.user', this.user);
    this.blockUI.start('Actualizando datos...');
    const res = await this.usersService.updateProfile(this.user.id, this.user);
    console.log('res', res);
    this.blockUI.stop();

    this.router.navigate(['profile']);

  }

}
