<div class="dialog">
    <div class="title">
        <h2>Actualizar propiedad</h2>
        <button mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    

    <form #form="ngForm" (ngSubmit)="save()">
        <div class="form-group">
            <label for="status">Estado</label>
            <select [(ngModel)]="model.propertyStatus" name="status">
                <option *ngFor="let item of statuses" [value]="item">{{item}}</option>
            </select>
        </div>
        <ng-container *ngIf="model.propertyStatus==='VENDIDO'">
            <div class="form-group">
                <label for="paymentType">Tipo de Pago</label>
                <select [(ngModel)]="model.paymentType" name="paymentType">
                    <option *ngFor="let item of paymentTypes" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="totalPayments">Número de Cuotas*</label>
                <input type="number" name="totalPayments" placeholder="0" autocomplete="off"
                    [(ngModel)]="model.totalPayments" required min="1" #totalPayments="ngModel">
                <div *ngIf="totalPayments.invalid && (totalPayments.dirty || totalPayments.touched)" class="alert">
                    <span *ngIf="totalPayments.errors?.min">La cantidad de quotas debe ser mayor a CERO.</span>
                </div>
            </div>
            <div class="form-group">
                <label for="pendingPayments">Cuotas pendientes*</label>
                <input type="number" name="pendingPayments" placeholder="" autocomplete="off"
                    [(ngModel)]="model.pendingPayments" required min="0" #pendingPayments="ngModel">
                <div *ngIf="pendingPayments.invalid && (pendingPayments.dirty || pendingPayments.touched)"
                    class="alert">
                    <span *ngIf="pendingPayments.errors?.min">La cantidad de cuotas pendiente debe ser mayor o igual a
                        CERO.</span>
                </div>
            </div>
            <div class="form-group">
                <label for="paymentDate">Fecha de pago*</label>
                <div class="input-wrapper">
                    <input type="text" name="paymentDate" placeholder="" autocomplete="off"
                        [(ngModel)]="model.paymentDate" required #paymentDate="ngModel" readonly
                        [matDatepicker]="picker">
                    <div class="input-button">
                        <mat-icon (click)="picker.open()">calendar_month</mat-icon>
                    </div>
                    <mat-datepicker #picker [startAt]="model.paymentDate"></mat-datepicker>
                </div>
                <div *ngIf="paymentDate.invalid && (paymentDate.dirty || paymentDate.touched)" class="alert">
                    <span *ngIf="paymentDate.errors?.required">Es obligatorio ingresar <strong>fecha</strong>.</span>
                </div>
            </div>
        </ng-container>

        <button type="submit" class="btn actions" [disabled]="!form.valid">Guardar</button>

    </form>
</div>