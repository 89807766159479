import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoginService } from 'src/app/shared/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  email: string;
  password: string;
  errorMessage: string;

  constructor(private loginService: LoginService, private router: Router) {}

  async onSubmit(): Promise<void> {
    this.blockUI.start('Iniciando sesión...');
    if (this.email && this.password) {
      try {
        const response = await this.loginService.login(this.email, this.password, 'LOCAL');
        if (response) {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('userName', response.data.user.name);
          this.router.navigate(['/reserve']);
        }
      } catch (error) {
        this.errorMessage = 'Correo o contraseña incorrectos.';
      }
    } else {
      console.log('Formulario inválido');
    }
    this.blockUI.stop();
  }

  ngOnInit(): void {
  }

}
