<app-toolbar [usuario]="userName" (toggleMenu)="toggleSidenav()"></app-toolbar>
  
<div class="main-container">
  <block-ui></block-ui>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #drawer mode="side" [opened]="false">
        <mat-nav-list>
          <a mat-list-item routerLink="/reserve" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <mat-icon>shopping_cart</mat-icon> Aprobación de reservas
          </a>
          <a mat-list-item routerLink="/property" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <mat-icon>pin_drop</mat-icon> Consulta de propiedades
          </a>
          <a mat-list-item routerLink="/update-project-plan" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <mat-icon>assignment</mat-icon> Actualizar Plan de Proyecto
          </a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
</div>  