<div class="reserve-container">
  <h2><mat-icon>confirmation_number</mat-icon> Lista de Reservas</h2>
  <div class="table-container">
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>Proyecto</th>
            <th>Lote</th>
            <th>Precio Lote</th>
            <th>Monto Voucher</th>
            <th>Usuario</th>
            <th>Voucher</th>
            <th>Estatus</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let checkout of checkouts">
            <td class="project">{{ checkout.property.project.name }}</td>
            <td class="code">{{ checkout.property.code }}</td>
            <td class="price">S/. {{ checkout.property.price }}</td>
            <td class="amount">S/. {{ checkout.amount }}</td>
            <td class="user">{{ checkout.createdBy }}</td>
            <td class="voucher">
              <button (click)="openImageModal(checkout.voucher.imagen)">
                Mostrar Imagen
              </button>
            </td>
            <td class="status">{{ checkout.status }}</td>
            <td class="action-buttons">
              <button (click)="approveCheckout(checkout)" class="approve-btn">
                Aprobar
              </button>
              <button (click)="rejectCheckout(checkout)" class="reject-btn">
                Rechazar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal -->
  <app-image-modal
    *ngIf="showImageModal"
    [imageUrl]="modalImageUrl"
    (close)="closeImageModal()"
  ></app-image-modal>
</div>
