<div class="container">
    <div class="filter">
      <label for="proyecto">Proyecto*</label>
      <select id="proyecto" [(ngModel)]="selectedProyecto">
        <option *ngFor="let proyecto of proyectos" [value]="proyecto.id">{{ proyecto.name }}</option>
      </select>
    </div>
  
    <div class="file-upload">
      <label for="file">Subir Plan del Proyecto*</label>
      <input type="file" (change)="onFileSelected($event)" />
    </div>
  
    <button (click)="updateProjectPlan()">Actualizar Plan</button>
  </div>
  