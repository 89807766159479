import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private apiUrl = `${environment.apiUrl}/projects`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log(
        `An error occurred ${error.status}, body was: ${error.error}`
      );
    } else {
      console.log(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
    }
    return throwError(
      'Something happened with request, please try again later.'
    );
  }

  getProjects(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}`)
    .pipe(retry(2), catchError(this.handleError))
    .toPromise();
  }

  updatePlanProject(projectId: string, file: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders().set('Authorization', token);

    return this.http.post<any>(`${this.apiUrl}/plan/${projectId}`, formData, {headers})
      .pipe(retry(2), catchError(this.handleError))
      .toPromise();
  }
}
