import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProjectService } from 'src/app/shared/project.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-project-plan',
  templateUrl: './update-project-plan.component.html',
  styleUrls: ['./update-project-plan.component.css']
})
export class UpdateProjectPlanComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  proyectos: any[] = [];
  selectedProyecto: string;
  selectedFile!: File;

  constructor(private projectService: ProjectService) { }

  async ngOnInit(): Promise<void> {
    await this.listProjects();
  }

  async listProjects(): Promise<void> {
    this.blockUI.start('Cargando lista de proyectos...');
    try {
      const response = await this.projectService.getProjects();
      if (response && Array.isArray(response.data)) {
        this.proyectos = response.data;
        this.selectedProyecto = this.proyectos[0].id;
      }
    } catch (error) {
      console.error('Error al obtener proyectos:', error);
      throw error;
    }
    this.blockUI.stop();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files.length > 0) {
      const file = input.files[0];
      this.resizeImage(file, 500, 1000).then((resizedFile) => {
        this.selectedFile = resizedFile;
      }).catch(err => {
        Swal.fire('Error', 'Error al redimensionar la imagen.', 'error');
      });
    }
  }

  async updateProjectPlan(): Promise<void> {
    if (this.selectedProyecto && this.selectedFile) {
      try {
        await this.projectService.updatePlanProject(this.selectedProyecto, this.selectedFile);
        await Swal.fire('Plan actualizado', `El plan del projecto ha sido actualizado`, 'success');
      } catch (error) {
        this.blockUI.stop();
        Swal.fire('Error', 'Error al actualizar el plan.', 'error');
      }
    } else {
      Swal.fire('Error', 'Debes seleccionar un proyecto y un archivo.', 'error');
    }
  }

  async updateStatusProperty(id: string, status: string): Promise<void> {
    this.blockUI.start('Actualizando plan del proyecto...');
    try {
      await this.projectService.updatePlanProject(this.selectedProyecto, this.selectedFile);
    } catch (error) {
      throw error;
    }
    this.blockUI.stop();
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calcula las nuevas dimensiones manteniendo la proporción
          let width = img.width;
          let height = img.height;
          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = Math.round(maxWidth / aspectRatio);
            } else {
              height = maxHeight;
              width = Math.round(maxHeight * aspectRatio);
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
              resolve(resizedFile);
            } else {
              reject(new Error('Blob is null'));
            }
          }, file.type);
        };
        img.onerror = (err) => reject(err);
      };
      reader.onerror = (err) => reject(err);
    });
  }
}
