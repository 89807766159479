import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CheckoutService } from 'src/app/shared/checkout.service';

@Component({
  selector: 'app-property-edit',
  templateUrl: './property-edit.component.html',
  styleUrls: ['./property-edit.component.css']
})
export class PropertyEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  model: any = {};

  statuses = ['DISPONIBLE', 'VENDIDO'];
  paymentTypes = ['CONTADO', 'EN CUOTAS'];

  property: any = {};

  constructor(private checkoutService: CheckoutService, public dialogRef: MatDialogRef<PropertyEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string, status: string }) {

    this.property = this.data;
    this.model.propertyId = data.id;

  }

  async ngOnInit(): Promise<void> {

    this.blockUI.start("Cargando...")
    const checkout = await this.checkoutService.getCheckoutByPropertyId(this.model.propertyId);
    console.log('checkout.data', checkout.data);
    if (!checkout.data) {
      this.model.propertyStatus = this.property.status ?? this.statuses[0];
      this.model.paymentType = this.property.paymentType ?? this.paymentTypes[0];
      this.model.paymentDate = new Date();

      this.model.totalPayments = 0;
      this.model.pendingPayments = 0;
    } else {
      this.model.checkoutId = checkout.data.id;
      this.model.propertyStatus = this.property.status ?? this.statuses[0];
      this.model.paymentType = checkout.data.paymentType ?? this.paymentTypes[0];
      if (checkout.data.credit) {
        this.model.paymentDate = checkout.data.credit.paymentDate;
        this.model.totalPayments = checkout.data.credit.totalPayments;
        this.model.pendingPayments = checkout.data.credit.pendingPayments;
      }

    }
    this.blockUI.stop();

  }

  async save() {
    console.log('model', this.model);

    this.blockUI.start("Guardando...")
    const response = await this.checkoutService.update(this.model.checkoutId, this.model);

    this.blockUI.stop();
    this.dialogRef.close({ valid: true });
  }

  closeDialog() {
    this.dialogRef.close({ valid: false });
  }

}
