<h2 mat-dialog-title class="dialog-header">
  <div class="header-left">
    <span class="property-title">Propiedad:</span>
    <span class="property-code">{{ property?.code }}</span>
  </div>

  <div class="header-center">
    <span>{{ property?.project.name }}</span>
  </div>

  <div class="header-right">
    <button mat-button [disabled]="true" [ngClass]="getStatusClass(property?.status)">
      {{ property?.status }}
    </button>
    <button *ngIf="!isMobile" mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h2>
<mat-divider></mat-divider>
<div class="dialog-content">
  <div class="custom-tab-container">
    <!-- Pestañas personalizadas -->
    <div class="custom-tab" [class.active]="activeTab === 1" (click)="setActiveTab(1)">
      <mat-icon class="tab-icon">home</mat-icon>
      <span>Datos de Propiedad</span>
    </div>
    <div class="custom-tab" [class.active]="activeTab === 2" [class.disabled]="checkout === null"
      (click)="checkout !== null ? setActiveTab(2) : null">
      <mat-icon class="tab-icon">shopping_cart</mat-icon>
      <span>Compra</span>
    </div>
  </div>
  
  <!-- Contenido de las pestañas -->
  <div class="custom-tab-content" *ngIf="activeTab === 1">
    <div class="input-container">
      <!-- Primera fila -->
      <div class="input-row">
        <div class="input-group small">
          <label for="input1">Nro</label>
          <input id="input1" type="text" [value]="separarCodigo(property.code, 1)" disabled>
        </div>
        <div class="input-group medium">
          <label for="input2">Manzana</label>
          <input id="input2" type="text" [value]="separarCodigo(property.code, 2)" disabled>
        </div>
        <div class="input-group large">
          <label for="input3">Tipo</label>
          <input id="input3" type="text" [(ngModel)]="property.type.name" disabled>
        </div>
      </div>
  
      <!-- Segunda fila -->
      <div class="input-row">
        <div class="input-group small">
          <label for="input4">Precio m2</label>
          <input id="input4" type="text" disabled>
        </div>
        <div class="input-group medium">
          <label for="input5">Precio Base</label>
          <input id="input5" type="text" [(ngModel)]="property.price" disabled>
        </div>
        <div class="input-group large">
          <label for="input6">Precio Comercial</label>
          <input id="input6" type="text" disabled>
        </div>
      </div>
  
      <!-- Tercera fila -->
      <div class="input-row">
        <div class="input-group small">
          <label for="input7">Area</label>
          <input id="input7" type="text" [(ngModel)]="property.area" disabled>
        </div>
        <div class="input-group medium">
          <label for="input8">Perímetro</label>
          <input id="input8" type="text" [(ngModel)]="property.perimeter" disabled>
        </div>
        <div class="input-group large">
          <label for="input9">Medidas</label>
          <div class="medidas">
            <span>F:</span>
            <span class="medidas-property">{{ property.measures.front }}</span>
            <span>A:</span>
            <span class="medidas-property">{{ property.measures.back }}</span>
            <span>I:</span>
            <span class="medidas-property">{{ property.measures.left }}</span>
            <span>D:</span>
            <span class="medidas-property">{{ property.measures.right }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-tab-content" *ngIf="activeTab === 2">
    <div class="input-container">
      <!-- Primera fila: Input grande -->
      <div class="input-row">
        <div class="input-group full-width">
          <label for="input1">Comprador</label>
          <input id="input1" type="text" [value]="checkout.user.name + ' ' + checkout.user.lastname" disabled>
        </div>
      </div>
      
      <!-- Segunda fila: Dos inputs medianos -->
      <div class="input-row">
        <div class="input-group medium">
          <label for="input2">Fecha Reserva</label>
          <input id="input2" type="text" [value]="formatDate(checkout.createdAt)" disabled>
        </div>
        <div class="input-group medium">
          <label for="input3">Confirmado Por:</label>
          <input id="input3" type="text" [(ngModel)]="checkout.updatedBy" disabled>
        </div>
  
        <div class="input-group comprobante" (click)="openImageModal()">
          <mat-icon class="clip-icon">attach_file</mat-icon>
          <span>Comprobante</span>
        </div>
      </div>
  
      <!-- Tercera fila: Dos inputs medianos y botón con icono de clip -->
      <div class="input-row">
        <div class="input-group medium">
          <label for="input4">Fecha Venta</label>
          <input id="input4" type="text" [value]="formatDate(checkout.updatedAt)" disabled>
        </div>
        <div class="input-group medium">
          <label for="input5">Vendida Por:</label>
          <input id="input5" type="text" [(ngModel)]="checkout.updatedBy" disabled>
        </div>
        <div class="input-group empty-space"></div>
      </div>
    </div>
  
  </div>
</div>


<!-- Modal de imagen -->
<app-image-modal *ngIf="showImageModal" [imageUrl]="modalImageUrl" (close)="closeImageModal()"></app-image-modal>