<div class="page-container">
    <div class="header">
        <h2><mat-icon>home</mat-icon> Consulta de Propiedades</h2>
    </div>

    <div class="filters-container">
        <div class="filter">
            <label for="proyecto">Proyecto*</label>
            <select id="proyecto" [(ngModel)]="selectedProyecto">
                <option *ngFor="let proyecto of proyectos" [value]="proyecto.id">{{ proyecto.name }}</option>
            </select>
        </div>

        <div class="filter">
            <label for="codigo">Código</label>
            <input type="text" id="codigo" placeholder="Código" [(ngModel)]="codigo" (input)="onInputChange()">
        </div>

        <div class="filter">
            <label for="estado">Estado</label>
            <select id="estado" [(ngModel)]="estado" (change)="onStateChange()">
                <option value="TODOS">TODOS</option>
                <option value="DISPONIBLE">DISPONIBLE</option>
                <option value="PRE-RESERVA">PRE-RESERVA</option>
                <option value="RESERVADO">RESERVADO</option>
                <option value="VENDIDO">VENDIDO</option>
            </select>
        </div>
    </div>

    <div class="lots-list">
        <div class="lot-item" *ngFor="let lot of pagedLots">
            <div class="lot-info">
                <div class="lot-code">{{ lot.code }}</div>
                <div class="lot-details">
                    <div><strong>{{ lot.area }} m²</strong></div>
                    <div><strong>S/. {{ lot.price }}</strong></div>
                </div>
            </div>
            <div class="prue">
                <div class="lot-status available">
                    <button [ngClass]="getStatusClass(lot.status)" (click)="changeLotStatus(lot)">
                        {{ lot.status }}
                    </button>
                </div>
                <button class="details-btn" (click)="viewDetails(lot)">Ver Detalle</button>
            </div>
        </div>
    </div>
    <mat-paginator [length]="lots.length" [pageSize]="pageSize" (page)="onPageChange($event)">
    </mat-paginator>    
</div>