import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private apiUrl = `${environment.apiUrl}/checkout`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log(
        `An error occurred ${error.status}, body was: ${error.error}`
      );
    } else {
      console.log(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
    }
    return throwError(
      'Something happened with request, please try again later.'
    );
  }

  getCheckouts(): Promise<any> {
    const token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.get<any>(this.apiUrl, { headers })
      .pipe(retry(2), catchError(this.handleError))
      .toPromise();
  }

  getCheckoutByPropertyId(id: string): Promise<any> {
    const token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.get<any>(`${this.apiUrl}/${id}`, { headers })
      .pipe(retry(2), catchError(this.handleError))
      .toPromise();
  }

  updateStatus(id: string, status: string, motive?: string): Promise<any> {
    const token = localStorage.getItem('accessToken');
    let body: any;
    if (motive) {
      body = { id, status, motive };
    } else {
      body = { id, status };
    }
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post<any>(`${this.apiUrl}/change-status`, body, { headers })
      .pipe(retry(2), catchError(this.handleError))
      .toPromise();
  }

  update(id: string, checkout: any): Promise<any> {
    const token = localStorage.getItem('accessToken');

    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.put<any>(`${this.apiUrl}/credit`, checkout, { headers })
      .pipe(retry(2), catchError(this.handleError))
      .toPromise();
  }
}
