import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private apiUrl = `${environment.apiUrl}/properties`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log(
        `An error occurred ${error.status}, body was: ${error.error}`
      );
    } else {
      console.log(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
    }
    return throwError(
      'Something happened with request, please try again later.'
    );
  }

  getPropertiesByCodeAndStatusAndProject(code: string, status: string, project: string): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}?code=${code}&status=${status}&project=${project}`)
    .pipe(retry(2), catchError(this.handleError))
    .toPromise();
  }

  updateStatus(id: string, status: string): Promise<any> {
    const body = { id, status };
    const token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post<any>(`${this.apiUrl}/update-status`, body, {headers})
    .pipe(retry(2), catchError(this.handleError))
    .toPromise();
  }
}
