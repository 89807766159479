<mat-toolbar class="toolbar">
  <button mat-icon-button (click)="onToggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="logo" src="https://res.cloudinary.com/dbeidfswx/image/upload/v1727886671/logo-inmobiliaria_sujekt.png" alt="Logo Inmobia">
  <span class="spacer"></span>
  <span>Hola <span class="name">{{ usuario.nombre }}</span></span>
  <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-circle">
    {{ usuario?.nombre ? usuario.nombre[0] : '' }}
  </button>

  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="profile()">
      <mat-icon>person</mat-icon>
      <span>Mi Perfil</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>
</mat-toolbar>
