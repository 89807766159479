import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  @ViewChild('drawer') drawer: MatSidenav;

  userName = { nombre: '' };

  toggleSidenav() {
    this.drawer.toggle();
  }

  ngOnInit(): void {
    const name = localStorage.getItem('userName');
    if (name) {
      this.userName = { nombre: name };
    }
  }
}
