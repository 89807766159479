<div class="page-container">
    <div class="header">
        <h2><mat-icon>person</mat-icon> Mi Perfil</h2>
    </div>

    <section class="section-container">
        <form #form="ngForm" class="form-grid">
            <div class="form-group">
                <label for="name">Nombre*</label>
                <input type="text" name="name" placeholder="José" autocomplete="off" [(ngModel)]="user.name" required
                    minlength="3" #name="ngModel">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                    <span *ngIf="name.errors?.required">Es obligatorio ingresar <strong>nombre</strong>.</span>
                </div>

            </div>

            <div class="form-group">
                <label for="lastname">Apellidos*</label>
                <input type="text" name="lastname" placeholder="Apellidos*" autocomplete="off"
                    [(ngModel)]="user.lastname" required #lastname="ngModel">
                <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="alert">
                    <span *ngIf="lastname.errors?.required">Es obligatorio ingresar
                        <strong>apellidos</strong>.</span>
                </div>
            </div>
            <div class="form-group">
                <label for="identificationType">Tipo Identidad*</label>
                <select [(ngModel)]="user.identificationType" name="identificationType">
                    <option *ngFor="let item of identificationTypes" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="identificationNumber">Número identidad*</label>
                <input type="text" name="identificationNumber" placeholder="" autocomplete="off"
                    [(ngModel)]="user.identificationNumber" required #identificationNumber="ngModel">
                <div *ngIf="identificationNumber.invalid && (identificationNumber.dirty || identificationNumber.touched)"
                    class="alert">
                    <span *ngIf="identificationNumber.errors?.required">Es obligatorio ingresar
                        <strong>número de identidad</strong>.</span>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Correo electrónico*</label>
                <input type="email" name="email" placeholder="Jose@gmail.com" readonly [(ngModel)]="user.email"
                    #email="ngModel">
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert">
                    <span *ngIf="email.errors?.required">Es obligatorio ingresar
                        <strong>email</strong>.</span>
                </div>
            </div>
            <div class="form-group">
                <label for="celular">Celular</label>
                <input type="text" name="phone" placeholder="Celular" autocomplete="off" [(ngModel)]="user.phone">
            </div>
            <div class="form-group" *ngIf="user.preferences">
                <label for="celular">Preferencia Compra*</label>
                <select [(ngModel)]="user.preferences.purchaseType" name="purchaseType">
                    <option *ngFor="let item of purchaseTypes" [value]="item">{{item}}</option>
                </select>
            </div>


        </form>
    </section>

    <button type="button" class="btn" (click)="save()" [disabled]="!form.valid">
        Guardar
    </button>
</div>