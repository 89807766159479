import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CheckoutService } from 'src/app/shared/checkout.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-property-details-dialog',
  templateUrl: './property-details-dialog.component.html',
  styleUrls: ['./property-details-dialog.component.css']
})
export class PropertyDetailsDialogComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  activeTab: number = 1;
  checkout: any = null;
  showImageModal = false;
  modalImageUrl = '';
  isMobile: boolean = false;

  setActiveTab(tabNumber: number) {
    this.activeTab = tabNumber;
  }

  constructor(
    public dialogRef: MatDialogRef<PropertyDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public property: any,
    private checkoutService: CheckoutService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    if(this.property.status === 'RESERVADO' || this.property.status === 'VENDIDO') {
      this.getCheckout();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async getCheckout(): Promise<void> {
    this.blockUI.start('Cargando lista de lotes...');
    try {
      const response = await this.checkoutService.getCheckoutByPropertyId(this.property.id);
      if (response) {
        this.checkout = response.data;
      }
    } catch (error) {
      console.error('Error al obtener propiedades:', error);
      throw error;
    }
    this.blockUI.stop();
  }

  getStatusClass(status: string) {
    switch (status) {
      case 'DISPONIBLE':
        return 'available';
      case 'PRE-RESERVA':
        return 'pre-reserved';
      case 'RESERVADO':
        return 'reserved';
      case 'VENDIDO':
        return 'sold';
      default:
        return '';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  separarCodigo(code: string, option: number): string {
    const match = code.match(/^([a-zA-Z]+)(\d+)$/);
    let letters = '';
    let numbers = '';
    if (match) {
      letters = match[1];
      numbers = match[2];
    }
    return (option === 1) ? letters : numbers;
  }

  openImageModal() {
    this.showImageModal = true;
    this.modalImageUrl = this.checkout.voucher.imagen;
  }

  closeImageModal() {
    this.showImageModal = false;
  }

  formatDate(isoString: string): string {
    const date = new Date(isoString);
    return date.toLocaleString('es-PE', { timeZone: 'America/Lima' });
  }
}
